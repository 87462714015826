import React from "react";
import { Link } from "gatsby"

const SliderMenu = ({active, links}) => {
  let extraClasses;
  if (active === null) {
    extraClasses = " dn";
  } else {
    extraClasses = (active ? " fadeIn" : " fadeOut");
  }
  return (
    <div
      className={
        "flex flex-column justify-center items-center bg-washed-red top z-max w-100 ease" + (active ? " vh-93" : " h0")
      }>
      {links.map((link, key) => <Link key={key}
        to={link.to}
        className={"sans-serif ttu mid-gray f5 no-underline menu__item pv3" + extraClasses}
      >{link.name}</Link>)}
    </div>
  )
}

export default SliderMenu;
