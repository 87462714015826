import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
} from 'react-icons/fa';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle: title
            instagram
            facebook
            twitter
          }
        }
      } 
    `}
    render={data => (
      <footer className="pa2 bg-dark-gray near-white pv5">
        <div className="flex flex-wrap justify-around w-100 mw9 center mb5">
          <div className="w-100 mw5 mb4">
            <hr />
            <div className="w-100 flex justify-around items-center pv2">
              {data.site.siteMetadata.facebook && (
                <a
                  className="near-white"
                  href={data.site.siteMetadata.facebook}
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  <FaFacebookF aria-label="facebook"/>
                </a>
              )}

              {data.site.siteMetadata.instagram && (
                <a
                  className="near-white"
                  href={data.site.siteMetadata.instagram}
                  rel="nofollow noreferrer"
                  target="_blank"
                >
                  <FaInstagram aria-label="instagram"/>
                </a>
              )}

              {data.site.siteMetadata.twitter && (
                <a className="near-white" href={data.site.siteMetadata.twitter}
                   rel="nofollow noreferrer"
                   target="_blank"
                >
                  <FaTwitter aria-label="twitter"/>
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="w-100 mw9 center silver mb3">
          <div className="w-100 bb b--mid-gray mv3"></div>
          <div className="flex w-100 mw6 items-center justify-center justify-start-ns">
            <a href="/sitemap.xml" className="white sans-serif f5 tracked pv1 db mh1">SITEMAP</a>
            <span className="mh1">|</span>
            <Link to="/politica-privacidad/" className="white sans-serif f5 tracked pv1 db mh1" rel="nofollow">PRIVACIDAD</Link>
            <span className="mh1">|</span>
            <Link to="/contacto/" className="white sans-serif f5 tracked pv1 db mh1" rel="nofollow">CONTACTO</Link>
            <span className="mh1">|</span>
            <Link to="/aviso-legal/" className="white sans-serif f5 tracked pv1 db mh1" rel="nofollow">AVISO LEGAL</Link>
          </div>
        </div>
        <div className="w-100 mw9 white center sans-serif f6">
          <p>Labial10 (C) 2018</p>
          <p>El propietario de esta web, Labial10.com, participa en el Programa de Afiliados de Amazon EU, un programa de publicidad para afiliados diseñado para ofrecer a sitios web un modo de obtener comisiones por publicidad, publicitando e incluyendo enlaces a Amazon.es. Amazon y el logo de Amazon son marcas registradas de Amazon.com, Inc. o sus afiliados.</p>
        </div>
      </footer>
    )} />
);

export default Footer;
