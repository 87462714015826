import React from 'react';
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import ReactGA from "react-ga";
import CookieConsent from "react-cookie-consent";
import {graphql, Link, useStaticQuery} from "gatsby";
import "./main.scss"
const Layout = ({title, children, location}) => {
const { site } = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                trackingId
            }
        }
    }
`);
return (
  <>
    <Navbar title={title}/>
    <main>
      {children}
    </main>
    <Footer />
  <CookieConsent
    location="bottom"
    buttonText="Aceptar"
    enableDeclineButton
    flipButtons={true}
    declineButtonText="Rechazar"
    cookieName="gatsby-gdpr-google-analytics"
    onAccept={() => {
        ReactGA.initialize(site.siteMetadata.trackingId)
        ReactGA.set({
            page: location.pathname,
            anonymizeIp: true,
        })
        ReactGA.pageview(location.pathname)
    }}
  >
      Las cookies de este sitio web se usan para personalizar los anuncios y analizar el tráfico.
    Puedes leer mas sobre nuestras políticas <Link to={'/politica-privacidad'}> aquí </Link>
  </CookieConsent>
  </>)
}

export default Layout;
