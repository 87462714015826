import React, {useState} from 'react';
import {graphql, Link, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import SliderMenu from "./nav_menu";
import { FiMenu } from 'react-icons/fi';

const Navbar = ({title}) => {

  const data = useStaticQuery(graphql`
      query {
          logo: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fixed(width: 112, height: 28) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          site {
              siteMetadata {
                  navbarLinks {
                      to
                      name
                  }
                  siteTitle: title
              }
          }
      }
  `);

  const [isActive, setActive] = useState(false);
  const toggleMenu = () => {
    setActive(!isActive);
  }

  return (
    <>
      <h1 className="f6 dark-gray display fw4 f6-l f2 tc sans-serif">{title}</h1>
      <div
        className="bg-white flex w-100 vh-7 pv3 flex justify-between items-center top-0 z-999 bb b--light-gray fixed">
        <div className="w-100 mw8 flex justify-between justify-around-l items-center ph4 pa2-ns">
          <button
            className="ttu tracked dark-gray f4 no-underline bn bg-transparent pointer"
            onClick={toggleMenu}>
            <FiMenu aria-label="menu"/>
          </button>

          <Link to="/" title="Labial10" className="display ttu tracked dark-gray f4 no-underline">
            <Img fixed={data.logo.childImageSharp.fixed} alt="Selección de los mejores labiales del momento" title="Selección de los mejores labiales del momento"/>
          </Link>
          {data.site.siteMetadata.navbarLinks.map(({to, name}, key) => <Link
              key={key}
              to={to}
              className="sans-serif ttu mid-gray f5 no-underline dn dib-l"
            >{name}</Link>
          )}
        </div>
        <div className="dn w-100 mw5 flex-l justify-around items-center">
          <span className="sans-serif mid-gray dn dib-l"></span>
        </div>
      </div>
      <SliderMenu
        active={isActive}
        links={data.site.siteMetadata.navbarLinks}
       />
    </>
)
}
export default Navbar;
